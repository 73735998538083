import React, { useEffect } from 'react';
import { IFaqArticle } from './faqPage';
import './faqArticlePage.scss';
import { FaqContact } from './faqContact';
import LocalizedLink from '../shared/localizedLink';
import { ISection } from './faqOverview';

const FaqArticlePage: React.FC<{
  pageContext: { article: IFaqArticle; section: ISection };
}> = ({ pageContext: { article, section } }) => {
  let relatedArticlesItems = null;
  if (article.related_articles) {
    relatedArticlesItems = article.related_articles!.map((relArt, i) => (
      <li key={i}>
        <LocalizedLink to={relArt.slug}>{relArt.title}</LocalizedLink>
      </li>
    ));
  }

  return (
    <div id='faq-article' className='main-padding'>
      <nav className='breadcrumb has-succeeds-separator' id='breadcrumb'>
        <ul>
          <li id='faq-breadcrumb'>
            <LocalizedLink to='/faq'>Frequently asked questions</LocalizedLink>
          </li>
          <li id='section-breadcrumb'>
            <LocalizedLink to={section.slug}>{section.title}</LocalizedLink>
          </li>
        </ul>
      </nav>
      <div className='columns'>
        <div className='column is-7' id='content'>
          <h3>{article.title}</h3>
          <div dangerouslySetInnerHTML={{ __html: article.content }} />
        </div>
        {relatedArticlesItems && (
          <div className='column is-3 is-offset-1' id='related-questions'>
            <h4>Related articles</h4>
            <ul>{relatedArticlesItems}</ul>
          </div>
        )}
      </div>
      <FaqContact />
    </div>
  );
};

export default FaqArticlePage;
